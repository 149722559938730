import * as React from 'react';
import { IBusStop } from 'src/models/BusStop';
import { TransitAgencyFund } from 'src/models/Funds';
import { EnabledPaymentType } from 'src/models/Payment';
import { ITransitAgency, IMetadata } from 'src/models/TransitAgency';
import create from 'zustand';
import shallow from 'zustand/shallow';

export interface Feature {
  id: number;
  name: string;
  type: string;
  metadata: IMetadata | null;
}

export interface TransitAgencyStore {
  busStops: Array<IBusStop>;
  setBusStops: (array: Array<IBusStop>) => void;
  features: Array<Feature> | null;
  setFeatures: (features: Array<Feature>) => void;
  getFeature: (featureType: string) => boolean | undefined;
  geoJson: any | null;
  setGeoJson: (geoJson: any) => void;
  transitAgencyDetails: ITransitAgency | null;
  setTransitAgencyDetails: (transitAgencyDetails: ITransitAgency) => void;
  enabledPaymentTypes: EnabledPaymentType[];
  setEnabledPaymentTypes: (enabledPaymentTypes: EnabledPaymentType[]) => void;
  timezoneDetails: { taTimezone: string; timezoneMismatch: boolean } | null;
  setTimezoneDetails: (timezoneDetails: { taTimezone: string; timezoneMismatch: boolean }) => void;
  funds: Array<TransitAgencyFund>;
  setFunds: (array: Array<TransitAgencyFund>) => void;
}

export const transitAgencyStore = create<TransitAgencyStore>((set, get) => ({
  busStops: [],
  setBusStops: (array) => set({ busStops: array }),
  features: null,
  setFeatures: (features) => set({ features }),
  getFeature: (featureType) => get().features?.some((feature) => feature.type === featureType),
  geoJson: null,
  setGeoJson: (geoJson) => set({ geoJson }),
  transitAgencyDetails: null,
  setTransitAgencyDetails: (transitAgencyDetails) => set({ transitAgencyDetails }),
  enabledPaymentTypes: [],
  setEnabledPaymentTypes: (enabledPaymentTypes) => set({ enabledPaymentTypes }),
  timezoneDetails: null,
  setTimezoneDetails: (timezoneDetails) => set({ timezoneDetails }),
  funds: [],
  setFunds: (array) => set({ funds: array })
}));

export const transitAgencyStoreHOC = <T extends TransitAgencyStore = TransitAgencyStore>(
  BaseComponent: React.ComponentType<T>
) => {
  return (props: Omit<T, keyof TransitAgencyStore>) => {
    const store = transitAgencyStore(
      (state) => ({
        busStops: state.busStops,
        setBusStops: state.setBusStops,
        features: state.features,
        setFeatures: state.setFeatures,
        getFeature: state.getFeature,
        geoJson: state.geoJson,
        setGeoJson: state.setGeoJson,
        transitAgencyDetails: state.transitAgencyDetails,
        setTransitAgencyDetails: state.setTransitAgencyDetails,
        enabledPaymentTypes: state.enabledPaymentTypes,
        setEnabledPaymentTypes: state.setEnabledPaymentTypes,
        timezoneDetails: state.timezoneDetails,
        setTimezoneDetails: state.setTimezoneDetails,
        funds: state.funds,
        setFunds: state.setFunds
      }),
      shallow
    );

    return <BaseComponent {...(props as T)} transitAgencyStore={store} />;
  };
};
