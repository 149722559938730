export enum TripStatus {
  ACCEPTED_OR_CONFIRMED = 'accepted_or_confirmed',
  ACCEPTED = 'accepted',
  ALL = 'all',
  BOARDED = 'boarded',
  CANCELLED_PAYMENT = 'cancelled_payment',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  CONFIRMED_DETAILS = 'confirmed_details',
  CONFIRMED_NO_DETAILS = 'confirmed_no_details',
  CONFIRMED = 'confirmed',
  ERROR = 'error',
  NO_SHOW = 'no_show',
  NONE = 'none',
  PENDING = 'pending',
  PROCESSING = 'processing',
  REJECTED = 'rejected',
  UNKNOWN = 'unknown',
  UPCOMING = 'upcoming',
  WAITING_FOR_PAYMENT = 'waiting_for_payment'
}

export enum TripType {
  OUTBOUND = 'outbound',
  RETURN = 'return'
}

// Series are all recucrring trips
// Occurrences are individual trips within the series
export enum RecurringTripType {
  SERIES = 'series',
  OCCURRENCE = 'occurrence'
}

export enum TripDateFilter {
  YESTERDAY = 'yesterday',
  TODAY = 'today',
  TOMORROW = 'tomorrow'
}

export enum RequestType {
  TRIP = 'trip',
  CHARTER = 'charter'
}

export enum MaxLength {
  NOTES_FOR_DRIVER = 200,
  NOTES_FOR_ADMIN = 200,
  PASSENGER_FEEDBACK = 500,
  DRIVER_FEEDBACK = 500
}

export enum RequestSourceTypes {
  ENGINE = 'trip_source_engine',
  ADHOC = 'trip_source_adhoc',
  PASSENGER_WEB = 'trip_source_web',
  PASSENGER_IOS = 'trip_source_ios',
  PASSENGER_ANDROID = 'trip_source_android'
}
