import * as React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';

export interface AuthStore {
  accessToken: string;
  setAccessToken: (token: string) => void;
}

export const authStore = create<AuthStore>((set) => ({
  accessToken: '',
  setAccessToken: (token) => set({ accessToken: token })
}));

export const authHOC = <T extends AuthStore = AuthStore>(BaseComponent: React.ComponentType<T>) => {
  return (props: Omit<T, keyof AuthStore>) => {
    const store = authStore(
      (state) => ({
        accessToken: state.accessToken,
        setAccessToken: state.setAccessToken
      }),
      shallow
    );

    return <BaseComponent {...(props as T)} authStore={store} />;
  };
};
