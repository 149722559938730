import { getCharterTrips } from 'src/api/charters';
import { getCalculatedWaypoints as getCalculatedWaypointsAPI } from 'src/api/route';
import { TripStatus } from 'src/enums/Trips';

import { getISOStringInUTC } from 'src/helpers/time';
import { Waypoint } from 'src/models/Location';
import { Demand } from 'src/models/Trip';
import { CharterRequestDetails } from 'src/store/charterRequest';

export const getPassengerDemandCapacity = (passengerDemand: Demand) => {
  const demands = Object.values(passengerDemand).flat();

  // similar as how backend gets number per capacity
  let numWheelchairs = 0;
  let numBikes = 0;
  let numNonDisabled = 0;

  demands.forEach((demand) => {
    if (!demand) return;

    if (!demand.bike && !demand.wheelchair && !demand.stroller && !demand.motorized_aid) {
      numNonDisabled += 1;
      return;
    }
    if (demand.wheelchair) {
      numWheelchairs += 1;
    }

    if (demand.bike) {
      numBikes += 1;
    }

    // stroller treated as wheelchair for capacity
    if (demand.stroller) {
      numWheelchairs += 1;
    }
    // motorized aid treated as wheelchair and normal for capacity
    if (demand.motorized_aid) {
      numWheelchairs += 1;
      numNonDisabled += 1;
    }
  });

  return { numWheelchairs, numBikes, numNonDisabled };
};

export const getCalculatedWaypoints = async (
  transitAgencyId: number | undefined,
  charterDetails: CharterRequestDetails
) => {
  const { origin, destination, stops } = charterDetails;
  const originHasAllInfo = origin.name && origin.longitude && origin.latitude;
  const destinationHasAllInfo = destination.name && destination.longitude && destination.latitude;
  const allStopsHaveRequiredFields = stops.every((stop) => {
    return stop.name !== '' && stop.latitude !== null && stop.longitude !== null;
  });

  if (originHasAllInfo && destinationHasAllInfo && allStopsHaveRequiredFields) {
    const charterWaypoints = [];

    // Add origin
    charterWaypoints.push({
      lat: origin.latitude,
      lon: origin.longitude,
      name: origin.name
    });

    // Add stops
    stops.forEach((stop) => {
      charterWaypoints.push({
        lat: stop.latitude,
        lon: stop.longitude,
        name: stop.name
      });
    });

    // Add destination
    charterWaypoints.push({
      lat: destination.latitude,
      lon: destination.longitude,
      name: destination.name
    });

    try {
      const reqBody = {
        transitAgencyId,
        waypoints: charterWaypoints
      };

      const waypointResponse = (await getCalculatedWaypointsAPI(reqBody)) as Waypoint[];
      const updatedCharterRequestDetails: CharterRequestDetails = {
        ...charterDetails,
        destination: {
          ...charterDetails.destination,
          durationInSeconds: undefined // Initialize durationInSeconds
        },
        stops: charterDetails.stops.map((stop) => ({
          ...stop,
          durationInSeconds: undefined // Initialize durationInSeconds
        }))
      };

      // Iterate through the destination and stops arrays
      for (const stop of [
        ...updatedCharterRequestDetails.stops,
        updatedCharterRequestDetails.destination
      ]) {
        // Find the matching object in the waypointResponse array by name
        const matchingWaypoint = waypointResponse.find(
          (waypoint) => waypoint.to.name === stop.name
        );

        // If a matching waypoint is found, update the stop object with durationInSeconds
        if (matchingWaypoint) {
          updatedCharterRequestDetails.totalDistanceMeters = waypointResponse.reduce(
            (accumulator: number, currentValue) => {
              return accumulator + currentValue.distanceInMeters;
            },
            0
          );

          updatedCharterRequestDetails.totalTimeDurationSeconds = waypointResponse.reduce(
            (accumulator: number, currentValue) => {
              return accumulator + currentValue.durationInSeconds;
            },
            0
          );

          if (stop === updatedCharterRequestDetails.destination) {
            updatedCharterRequestDetails.destination.durationInSeconds =
              matchingWaypoint.durationInSeconds;
          } else {
            stop.durationInSeconds = matchingWaypoint.durationInSeconds;
          }
        }
      }

      return updatedCharterRequestDetails;
    } catch (err) {
      console.log('charters::getCalculatedWaypoints', err);
    }
  }
};

// Check for charter trips from now to two weeks in the future
export const checkForUpcomingCharters = async (timezone: string) => {
  if (!timezone) {
    return;
  }

  const now = new Date();

  const twoWeeksFromNow = new Date(now);
  twoWeeksFromNow.setDate(now.getDate() + 14);
  // Set the time to the end of the day (23:59:59)
  twoWeeksFromNow.setHours(23, 59, 59, 999);

  const getSimplifiedTrips = true;
  const start = getISOStringInUTC(now, timezone);
  const end = getISOStringInUTC(twoWeeksFromNow, timezone);

  try {
    const { charterRequests } = await getCharterTrips(start, end, getSimplifiedTrips);

    if (charterRequests?.length) {
      const allUpcomingCharters = charterRequests
        .filter((charter) => charter.status === TripStatus.ACCEPTED)
        .map((charter) => ({
          charterId: charter.id,
          displayNotice: true
        }));

      return allUpcomingCharters;
    }

    return [];
  } catch (error) {
    console.log('charters::checkForUpcomingCharters', error);
    return [];
  }
};
