import * as React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';

export interface AnalyticsUrl {
  unsecuredUrl?: string;
  powerBIAppUrl?: string;
}

interface AnalyticsStore {
  analyticsUrl: AnalyticsUrl | null;
  setAnalyticsUrl: (url: AnalyticsUrl | null) => void;
}

export const analyticsStore = create<AnalyticsStore>((set) => ({
  analyticsUrl: null,
  setAnalyticsUrl: (url) => set({ analyticsUrl: url })
}));

export const analyticsHOC = <T extends AnalyticsStore = AnalyticsStore>(
  BaseComponent: React.ComponentType<T>
) => {
  return (props: Omit<T, keyof AnalyticsStore>) => {
    const store = analyticsStore(
      (state) => ({
        analyticsUrl: state.analyticsUrl,
        setAnalyticsUrl: state.setAnalyticsUrl
      }),
      shallow
    );

    return <BaseComponent {...(props as T)} analyticsStore={store} />;
  };
};
