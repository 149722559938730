import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import blaiseAnimation from '../../assets/images/blaise_loader.json';
import './BlaiseLoader.scss';

const BlaiseLoader = ({ fullScreen = false }) => {
  const blaiseLogo = useRef();

  useEffect(() => {
    lottie
      .loadAnimation({
        container: blaiseLogo.current,
        autoplay: true,
        loop: true,
        renderer: 'svg',
        animationData: blaiseAnimation,
        rendererSettings: {
          filterSize: {
            width: '100%',
            height: '100%'
          }
        }
      })
      .setSubframe(false);
  }, []);

  return (
    <div className="container" style={fullScreen ? { width: '100vw', height: '100vh' } : null}>
      <div
        className="blaise-loader"
        ref={blaiseLogo}
        style={fullScreen ? { width: '120px', height: '120px' } : null}
      />
    </div>
  );
};

export default BlaiseLoader;
