import realAxios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import config from '../config';
import { authStore } from '../store/auth';
import { accessTokenContainer } from '../services/authUtils';

interface DecodedToken extends JwtPayload {
  exp?: number;
}

interface Config extends AxiosRequestConfig {
  apiAudience?: string;
}

class AuthenticatedAxiosInstance {
  private static instance: AuthenticatedAxiosInstance | null = null;
  public axios: AxiosInstance;

  public constructor() {
    // Singleton logic.
    if (AuthenticatedAxiosInstance.instance === null) {
      AuthenticatedAxiosInstance.instance = this;
    }

    // Initialize the axios property with default headers.
    this.axios = realAxios.create({
      baseURL: config.apiServer,
      headers: {
        'x-app': 'dashboard',
        'accept-language': navigator.language.substring(0, 2)
      }
    });

    return AuthenticatedAxiosInstance.instance;
  }

  public createAuthenticatedAxiosInstance = (accessToken: string) => {
    this.axios = realAxios.create({
      baseURL: config.apiServer,
      headers: {
        'x-app': 'dashboard',
        'accept-language': navigator.language.substring(0, 2),
        Authorization: `Bearer ${accessToken}`
      }
    });

    this.axios.interceptors.request.use(
      async (config: Config) => {
        // jwtExpiration comes back as 10 digit UTC timestamp, so currentTime needs to be converted to a string, sliced and converted back to a number.
        const currentTime = Number(Date.now().toString().slice(0, 10));
        const token = authStore.getState().accessToken;
        const decodedToken: DecodedToken = jwt_decode(token);
        const jwtExpiration = decodedToken?.exp;
        let refresh = true;

        if (jwtExpiration && currentTime >= jwtExpiration && refresh) {
          // Switch refresh to false, so that it only tries to refresh accessToken once.
          refresh = false;

          // Gets new token.
          const newAccessToken = await accessTokenContainer.getAccessTokenSilently()({
            audience: config.apiAudience
          });

          // Switch authorization header to new token.
          config.headers['Authorization'] = `Bearer ${newAccessToken}`;

          // Set new token in Zustand store.
          authStore.getState().setAccessToken(newAccessToken);
        }

        return config;
      },
      (error) => {
        Promise.reject(error).catch((err) => console.log(err));
      }
    );
  };
}

const authenticatedAxiosInstance = new AuthenticatedAxiosInstance();

export default authenticatedAxiosInstance;
