import * as React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';
import produce from 'immer';
import { PaymentType } from '../enums/Payment';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { ITravelTimeData, IPriceObject, Demand } from 'src/models/Trip';
import { IMetadata } from 'src/models/TransitAgency';
import { ILocation } from 'src/models/Location';
import { IPassenger } from 'src/models/Passenger';
import { FormattedFundForPayment } from 'src/models/Funds';
import { ITripRequestOrganizationBody } from 'src/models/Organization';

export const WEEKDAYS = moment.weekdaysShort();

export interface CalledInRequestDetails {
  origin?: ILocation | string;
  destination?: ILocation | string;
  departureOrArrival?: string;
  time?: Date | null;
  originalTime?: string;
  notes?: string;
  notesForAdmin?: string;
  passengers?: Demand;
  priceObject?: IPriceObject;
  departTripPrice?: number;
  travelTimeData?: ITravelTimeData | null;
  bundleId?: string;
  paymentType?: string;
  payLaterType?: {
    paymentType: string;
    organizations: ITripRequestOrganizationBody[];
  };
  hasReturnTrip?: boolean;
  returnOrigin?: ILocation | null;
  returnDest?: ILocation | null;
  returnDepartureOrArrival?: string;
  returnTime?: Date | null;
  returnTripPrice?: number;
  isRecurringTrip?: boolean;
  eventParentTripId?: number;
  recurringDays?: string[];
  recurringTripStart?: Date;
  recurringTripEnd?: Date;
  metadata?: IMetadata | null;
  editRecurringTripType?: string;
  subsidyReduction?: number;
  subsidyFunds?: FormattedFundForPayment[] | null;
  selectedPassenger?: IPassenger;
  errorLocation?: string | null;
  requiresPreferredBus?: boolean;
  preferredBuses?: number[];
  preferredReturnTripBuses?: number[];
}

export interface CalledInRequestStore {
  calledInRequestDetails: CalledInRequestDetails;
  setCalledInRequestDetails: (updatedDetails: CalledInRequestDetails) => void;
  resetCalledInRequestDetails: () => void;
  calledInRequestRequiresAction: boolean;
  setCalledInRequestRequiresAction: (needsAction: boolean) => void;
  numberOfTripsThatRequireAction: number;
  currentCalledInRequestModalStep: number;
  setCurrentCalledInRequestModalStep: (newStep: number) => void;
}

const calledInRequestInitialState: CalledInRequestDetails = {
  origin: '',
  destination: '',
  departureOrArrival: 'departure',
  time: null,
  originalTime: '',
  notes: '',
  notesForAdmin: '',
  passengers: {},
  priceObject: {
    content: '',
    customPriceString: false,
    isActualPriceForLeg: false,
    isEstimation: false,
    price: 0
  },
  departTripPrice: 0,
  travelTimeData: null,
  bundleId: uuid(),
  paymentType: PaymentType.PAY_ON_BOARD,
  hasReturnTrip: false,
  returnOrigin: null,
  returnDest: null,
  returnDepartureOrArrival: 'departure',
  returnTime: null,
  returnTripPrice: 0,
  isRecurringTrip: false,
  eventParentTripId: 0, // 0 is an invalid eventParentTripId (not a recurring trip)
  recurringDays: WEEKDAYS.slice(1, -1),
  recurringTripStart: new Date(),
  recurringTripEnd: new Date(),
  metadata: null,
  editRecurringTripType: '',
  subsidyReduction: 0,
  subsidyFunds: [],
  errorLocation: null,
  requiresPreferredBus: false,
  preferredBuses: [],
  preferredReturnTripBuses: []
};

export const calledInRequestStore = create<CalledInRequestStore>((set) => ({
  calledInRequestDetails: calledInRequestInitialState,
  setCalledInRequestDetails: (updatedDetails) =>
    set(
      produce((state) => {
        state.calledInRequestDetails = { ...state.calledInRequestDetails, ...updatedDetails };
      })
    ),
  resetCalledInRequestDetails: () => set({ calledInRequestDetails: calledInRequestInitialState }),
  calledInRequestRequiresAction: false,
  setCalledInRequestRequiresAction: (needsAction) =>
    set({ calledInRequestRequiresAction: needsAction }),
  numberOfTripsThatRequireAction: 0,
  currentCalledInRequestModalStep: 1,
  setCurrentCalledInRequestModalStep: (newStep) => set({ currentCalledInRequestModalStep: newStep })
}));

export const calledInRequestDetailsHOC = <T extends CalledInRequestStore = CalledInRequestStore>(
  BaseComponent: React.ComponentType<T>
) => {
  return (props: Omit<T, keyof CalledInRequestStore>) => {
    const store = calledInRequestStore(
      (state) => ({
        calledInRequestDetails: state.calledInRequestDetails,
        setCalledInRequestDetails: state.setCalledInRequestDetails,
        resetCalledInRequestDetails: state.resetCalledInRequestDetails,
        calledInRequestRequiresAction: state.calledInRequestRequiresAction,
        setCalledInRequestRequiresAction: state.setCalledInRequestRequiresAction,
        numberOfTripsThatRequireAction: state.numberOfTripsThatRequireAction
      }),
      shallow
    );

    return <BaseComponent {...(props as T)} calledInRequestStore={store} />;
  };
};
