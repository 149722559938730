import { TFunction } from 'i18next';
import { getPassengerFunds, validatePassengerFundsForTrip } from 'src/api/funds';
import colors from 'src/assets/sass/colors';
import {
  FormattedFundForPayment,
  FundVerification,
  PassengerFund,
  TransitAgencyFund
} from 'src/models/Funds';
import { ITripRequestOrganizationBody } from 'src/models/Organization';

const DEFAULT_FUND_COVERAGE = 0.5;

export const updateFundsInStore = (funds: Array<TransitAgencyFund>) => {
  const formattedFunds = funds.map((fund) => {
    const tenPercent = fund.fundTotal * 0.1;
    const isLowInFunds = fund.fundBalance < tenPercent && fund.isActiveForTransitAgency;

    return {
      fundId: fund.fundId,
      fundName: fund.fundName,
      priority: fund.priority,
      isActiveForTransitAgency: fund.isActiveForTransitAgency,
      showWarning: isLowInFunds,
      fundEndDate: fund.fundEndDate,
      fundStartDate: fund.fundStartDate,
      fundTotal: fund.fundTotal,
      fundBalance: fund.fundBalance
    };
  });

  return formattedFunds;
};

export const getFundCoverageForTrip = (
  fund: PassengerFund,
  price: number,
  subsidyReduction: number,
  subsidyFunds: FormattedFundForPayment[],
  openSnackbar: (message: string, color: string) => void,
  t: TFunction
) => {
  const amountLeftAfterReduction = price - subsidyReduction;

  const hasInsuffientFunds =
    fund.fundBalance < amountLeftAfterReduction ||
    (fund.spendingAmount !== null && fund.spendingAmount == 0);

  if (hasInsuffientFunds) {
    openSnackbar(t('insufficient_funds'), colors.red);
  }

  const tripCoveredInPercentage = subsidyFunds.reduce(
    (acc: number, obj: FormattedFundForPayment) => acc + Number(obj.fundCoverage),
    0
  );

  // default new fund coverage to 50% unless the trip is already covered by 50% or more
  if (tripCoveredInPercentage <= DEFAULT_FUND_COVERAGE) {
    // if the fund is covering the last 50% of the trip price, use amountLeftAfterReduction to avoid errors with decimals when price is an odd number
    // eg. trip is 4.33, the first 50% is 2.16 and the second should be 2.17 (and not 2.16 after formatting to 2 decimal places)

    const amount =
      tripCoveredInPercentage === DEFAULT_FUND_COVERAGE
        ? amountLeftAfterReduction
        : // : parseInt(priceObject?.price * DEFAULT_FUND_COVERAGE);
          price * DEFAULT_FUND_COVERAGE;
    return { coverage: DEFAULT_FUND_COVERAGE, amount };
  } else {
    const calculatedCoverage = amountLeftAfterReduction / price;
    return { coverage: calculatedCoverage, amount: amountLeftAfterReduction };
  }
};

export const getFundsForPassenger = async (
  requestDetails: {
    passengerId: string;
    transitAgencyId: number;
    subsidyFunds: FormattedFundForPayment[]; // funds already saved in request store/context
    subsidyReduction: number;
    price: number;
  },
  setPassengersFunds: (funds: PassengerFund[]) => void,
  openSnackbar: (message: string, color: string) => void,
  t: TFunction,
  saveInitialFundToStore?: (funds: FormattedFundForPayment[]) => void
) => {
  const { passengerId, transitAgencyId, subsidyFunds, subsidyReduction, price } = requestDetails;

  const includeTrips = false;
  const response = await getPassengerFunds(passengerId, transitAgencyId, includeTrips);

  if (!response.length) {
    return;
  }

  // want funds that are active and have money left over
  const activeFunds = response.filter(
    (fund: PassengerFund) =>
      fund.isActiveForTransitAgency &&
      fund.isActiveForPassenger &&
      fund.fundBalance > 0 &&
      (fund.spendingAmount === null || fund.spendingAmount > 0)
  );

  if (activeFunds?.length) {
    // save based on TA priority
    activeFunds.sort((a: PassengerFund, b: PassengerFund) => a.priority - b.priority);
    setPassengersFunds(activeFunds);
  }

  // if there are no subsidy funds already saved but there are active funds
  if (!subsidyFunds.length && activeFunds.length) {
    const coveredByFund = getFundCoverageForTrip(
      activeFunds[0],
      price,
      subsidyReduction,
      subsidyFunds,
      openSnackbar,
      t
    );

    const fund = {
      fundId: activeFunds[0].fundId,
      fundName: activeFunds[0].fundName,
      fundBalance: activeFunds[0].fundBalance,
      spendingAmount: activeFunds[0].spendingAmount,
      fundCoverage: coveredByFund?.coverage || null,
      fundCost: coveredByFund?.amount ? (coveredByFund.amount / 100).toFixed(2) : null
    };

    if (saveInitialFundToStore) {
      saveInitialFundToStore([{ ...fund }]);
    }
  }
};

export const verifyFundsForPayment = async (requestDetails: {
  passengerId: string;
  transitAgencyId: number;
  subsidyFunds: FormattedFundForPayment[];
  hasReturnTrip: boolean;
}) => {
  const { transitAgencyId, passengerId, subsidyFunds, hasReturnTrip } = requestDetails;
  if (!transitAgencyId || !passengerId) {
    return;
  }

  const reqBody = subsidyFunds.map((fund: FormattedFundForPayment) => {
    const fundCost = Number(fund.fundCost) * 100;

    return {
      fundId: fund.fundId,
      fundCost: hasReturnTrip ? fundCost * 2 : fundCost,
      fundPercentage: fund.fundCoverage
    };
  });

  const response = await validatePassengerFundsForTrip(passengerId, transitAgencyId, {
    fundsArray: reqBody
  });

  let errorMessage = 'error_generic';

  const allFundsValidated = response?.every((fund: FundVerification) => {
    if (fund.passengerFundIsDepleted || fund.transitAgencyFundIsDepleted) {
      errorMessage = 'errors:depleted_fund';
    }

    if (!fund.passengerFundIsActive || !fund.transitAgencyFundIsActive) {
      errorMessage = 'errors:expired_fund';
    }

    return (
      fund.passengerFundIsActive &&
      fund.transitAgencyFundIsActive &&
      !fund.passengerFundIsDepleted &&
      !fund.transitAgencyFundIsDepleted
    );
  });

  if (allFundsValidated) {
    return { validated: true, message: '' };
  } else {
    return { validated: false, message: errorMessage };
  }
};

export const verifyFundAndOrganizationReduction = (
  price: string | number,
  funds: FormattedFundForPayment[],
  organizations: ITripRequestOrganizationBody[]
) => {
  const totalFundsAmount = funds.reduce((acc, fund) => acc + Number(fund.fundCost), 0);
  const totalOrganizationsAmount = organizations.reduce(
    (acc, organization) => acc + Number(organization.amount),
    0
  );

  return totalFundsAmount + totalOrganizationsAmount <= Number(price);
};
