/* eslint-disable no-undef*/
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('dotenv').config();

// Maps env.REACT_APP_API_ADDR to the environment value
const environmentMap = {
  'https://api-dev.blaisetransit.com/v2': 'dev',
  'https://api-staging.blaisetransit.com/v2': 'staging',
  'https://api-demo.blaisetransit.com/v2': 'demo',
  'https://transfers.api-demo.blaisetransit.com/v2': 'demo-transfers',
  'https://api.blaisetransit.com/v2': 'prod',
  'https://ns.api-demo.blaisetransit.com/v2': 'ns-demo'
};

const config = {
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  apiAudience: process.env.REACT_APP_AUTH0_AUDIENCE,
  login: process.env.REACT_APP_AUTH0_LOGIN,
  password: process.env.REACT_APP_AUTH0_PASSWORD,
  firstName: process.env.REACT_APP_CREATE_USER_FIRSTNAME,
  lastName: process.env.REACT_APP_CREATE_USER_LASTNAME,
  email: process.env.REACT_APP_CREATE_USER_EMAIL,
  phone: process.env.REACT_APP_CREATE_USER_PHONE,
  appUrl: process.env.REACT_APP_ADDR,
  apiServer: process.env.REACT_APP_API_ADDR,
  mapBoxToken: process.env.REACT_APP_MAPBOX_ACCESS_TKN,
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
  version: process.env.REACT_APP_VERSION,
  environment: environmentMap[process.env.REACT_APP_API_ADDR] || 'unknown',
  // Firebase
  // routing-algo project
  routingAlgoFbApiKey: process.env.REACT_APP_ROUTING_ALGO_FB_API_KEY,
  routingAlgoFbAuthDomain: process.env.REACT_APP_ROUTING_ALGO_FB_AUTH_DOMAIN,
  routingAlgoFbProjectId: process.env.REACT_APP_ROUTING_ALGO_FB_PROJECT_ID,
  routingAlgoFbStorageBucket: process.env.REACT_APP_ROUTING_ALGO_FB_STORAGE_BUCKET,
  routingAlgoFbMessagingSenderId: process.env.REACT_APP_ROUTING_ALGO_FB_MESSAGING_SENDER_ID,
  routingAlgoFbAppId: process.env.REACT_APP_ROUTING_ALGO_FB_APP_ID,
  // passenger-app-native
  passengerAppNativeFbApiKey: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_API_KEY,
  passengerAppNativeFbAuthDomain: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_AUTH_DOMAIN,
  passengerAppNativeFbDatabaseUrl: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_DATABASE_URL, // routing-algo does not have this value!
  passengerAppNativeFbProjectId: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_PROJECT_ID,
  passengerAppNativeFbStorageBucket: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_STORAGE_BUCKET,
  passengerAppNativeFbMessagingSenderId:
    process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_MESSAGING_SENDER_ID,
  passengerAppNativeFbAppId: process.env.REACT_APP_PASSENGER_APP_NATIVE_FB_APP_ID,

  // engine
  engineWebFbApiKey: process.env.REACT_APP_ENGINE_WEB_FB_API_KEY,
  engineWebFbAuthDomain: process.env.REACT_APP_ENGINE_WEB_AUTH_DOMAIN,
  engineWebFbProjectId: process.env.REACT_APP_ENGINE_WEB_FB_PROJECT_ID,
  engineWebFbStorageBucket: process.env.REACT_APP_ENGINE_WEB_FB_STORAGE_BUCKET,
  engineWebFbMessagingSenderId: process.env.REACT_APP_ENGINE_WEB_FB_MESSAGING_SENDER_ID,
  engineWebFbAppId: process.env.REACT_APP_ENGINE_WEB_FB_APP_ID
};

export default config;
