import authenticatedAxiosInstance from '../axios/axios-authorized';

export const getTransitAgencyFunds = async (transitAgencyId, includeTrips = true) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/fund/transitagency/${transitAgencyId}?includeTrips=${includeTrips}`
  );

  return response?.data;
};

export const createTransitAgencyFund = async (transitAgencyId, reqBody) => {
  const response = await authenticatedAxiosInstance.axios.post(
    `/fund/transitagency/${transitAgencyId}`,
    reqBody
  );

  return response;
};

export const editTransitAgencyFund = async (transitAgencyId, reqBody) => {
  return await authenticatedAxiosInstance.axios.patch(
    `/fund/transitagency/${transitAgencyId}`,
    reqBody
  );
};

export const deactivateTransitAgencyFund = async (transitAgencyId, fundId) => {
  return await authenticatedAxiosInstance.axios.delete(
    `/fund/transitagency/${transitAgencyId}?fundId=${fundId}`
  );
};

export const getPassengerFunds = async (passengerId, transitAgencyId, includeTrips = false) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/fund/passenger/${passengerId}?includeTrips=${includeTrips}&transitAgencyId=${transitAgencyId}`
  );

  return response?.data;
};

export const createPassengerFund = async (passengerId, transitAgencyId, reqBody) => {
  return await authenticatedAxiosInstance.axios.post(
    `fund/passenger/${passengerId}?transitAgencyId=${transitAgencyId}`,
    reqBody
  );
};

export const editPassengerFund = async (passengerId, transitAgencyId, reqBody) => {
  return await authenticatedAxiosInstance.axios.patch(
    `fund/passenger/${passengerId}?transitAgencyId=${transitAgencyId}`,
    reqBody
  );
};

export const deletePassengerFund = async (passengerId, fundId) => {
  return await authenticatedAxiosInstance.axios.delete(
    `/fund/passenger/${passengerId}?fundId=${fundId}`
  );
};

export const validatePassengerFundsForTrip = async (passengerId, transitAgencyId, reqBody) => {
  const response = await authenticatedAxiosInstance.axios.post(
    `/fund/passenger/${passengerId}/check?transitAgencyId=${transitAgencyId}`,
    reqBody
  );

  return response?.data;
};
