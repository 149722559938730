import { withRouter, useRouteMatch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import colors from '../../assets/sass/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faBus,
  faBusAlt,
  faMapSigns,
  faUser,
  faUserTie,
  faSignOutAlt,
  faClock,
  faPhone,
  faHistory,
  faUsers,
  faRoute,
  faCommentDots,
  faChartBar,
  faCircle,
  faHome,
  faLock,
  faFunnelDollar,
  faExclamationTriangle,
  faFileInvoiceDollar,
  faCity,
  faBell
  // faMapMarkedAlt
} from '@fortawesome/free-solid-svg-icons';
import blaiseLogo from '../../assets/images/blaiseLogo.png';
import './NavBar.scss';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../config';
import { currentUserStore } from '../../store/user';
import { analyticsStore } from '../../store/analytics';
import { driverShiftStore } from '../../store/driverShift';
import { calledInRequestStore } from '../../store/calledInRequest';
import { TransitAgencyFeatures } from '../../enums/TransitAgency';
import { transitAgencyStore } from '../../store/transitAgency';
import { charterRequestStore } from 'src/store/charterRequest';

const NavBar = (props) => {
  const match = useRouteMatch('/blaise-admin-dashboard');
  const path = match ? match.path : null;
  const {
    location: { pathname }
  } = props;
  let history = useHistory();

  const { currentUser, setCurrentUser } = currentUserStore((state) => ({
    currentUser: state.currentUser,
    setCurrentUser: state.setCurrentUser
  }));
  const { analyticsUrl } = analyticsStore((state) => ({
    analyticsUrl: state.analyticsUrl
  }));

  const { driverShiftsAreEndingSoon } = driverShiftStore((state) => ({
    driverShiftsAreEndingSoon: state.driverShiftsAreEndingSoon
  }));
  const { calledInRequestRequiresAction } = calledInRequestStore((state) => ({
    calledInRequestRequiresAction: state.calledInRequestRequiresAction
  }));
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const { hasFundsFeature, hasChartersFeature, hasOrganizationsFeature, hasInvoiceFeature, funds } =
    transitAgencyStore((state) => ({
      hasFundsFeature: state.getFeature(TransitAgencyFeatures.FARE_ASSISTANCE_FUNDS),
      hasChartersFeature: state.getFeature(TransitAgencyFeatures.CHARTERS),
      hasOrganizationsFeature: state.getFeature(TransitAgencyFeatures.ORGANIZATIONS),
      hasInvoiceFeature: state.getFeature(TransitAgencyFeatures.INVOICES),
      funds: state.funds
    }));

  const { upcomingChartersWithinTwoWeeks } = charterRequestStore((state) => ({
    upcomingChartersWithinTwoWeeks: state.upcomingChartersWithinTwoWeeks
  }));

  const showNotificationForFunds = funds?.find((fund) => fund.showWarning);

  const checkPermissions = (permissionString) => {
    if (user) {
      const permissions = user['http://blaisetransitagency.com/permissions'];

      if (permissions.includes(permissionString)) {
        return true;
      }
    }
    return false;
  };

  const assignClassName = (path) => {
    return pathname === path ? 'selectedNavbarItem' : '';
  };

  const checkEmployeeManagementPermissions = () => checkPermissions('read:agents');
  const checkFleetManagementPermissions = () => checkPermissions('manage:fleets');
  const checkBusManagementPermissions = () => checkPermissions('manage:buses');
  const checkBusStopManagementPermissions = () => checkPermissions('manage:busStops');
  const checkDriverManagementPermissions = () => checkPermissions('manage:drivers');
  const checkDriverShiftManagementPermissions = () => checkPermissions('manage:driverShifts');
  const checkCalledInManagementPermissions = () => checkPermissions('manage:calledInRequests');
  const checkPassengerManagementPermissions = () => checkPermissions('manage:passengers');
  const checkSystemParametersManagementPermissions = () =>
    checkPermissions('manage:systemParameters');
  const checkMyProfilePermissions = () => checkPermissions('manage:myProfile');
  const checkBlaiseAdminDashboardPermissions = () => checkPermissions('read:superadmins');
  const checkHistoryManagementPermissions = () => checkPermissions('manage:history');
  const checkRouteManagmentPermissions = () => checkPermissions('manage:routes');
  const checkFundsManagmentPermissions = () => checkPermissions('manage:funds');
  const checkInvoicesManagmentPermissions = () => checkPermissions('manage:invoices');
  const checkOrganizationManagementPermissions = () =>
    checkPermissions('manage:organizations') && hasOrganizationsFeature;
  const checkPushNotificationsManagementPermissions = () =>
    checkPermissions('manage:pushNotifications');
  const displayUpcomingWarning = upcomingChartersWithinTwoWeeks.some(
    (charter) => charter.displayNotice
  );

  return (
    <Drawer className="drawer" variant="permanent" anchor="left">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        className="navBox navbar-right-border"
      >
        <Box>
          <img src={blaiseLogo} className="logoNavbar" alt="logo" />
          <MenuItem
            className={pathname === '/' ? 'selectedNavbarItem' : ''}
            button
            onClick={() => history.push('/')}
            selected={pathname === '/'}
          >
            <FontAwesomeIcon icon={faHome} fixedWidth />
            &ensp; {props.t('home')}
          </MenuItem>
          {analyticsUrl && Object.keys(analyticsUrl).length ? (
            <MenuItem
              className={assignClassName('/analytics')}
              button
              onClick={() => history.push('/analytics')}
              selected={pathname === '/analytics'}
            >
              <FontAwesomeIcon icon={faChartBar} fixedWidth />
              &ensp; {props.t('analytics_title')}
            </MenuItem>
          ) : null}
          {checkPushNotificationsManagementPermissions() && (
            <MenuItem
              className={assignClassName('/pushNotifications')}
              button
              onClick={() => history.push('/pushNotifications')}
              selected={pathname === '/pushNotifications'}
            >
              <FontAwesomeIcon icon={faBell} fixedWidth />
              &ensp; {props.t('push_notifications')}
            </MenuItem>
          )}
          {/* <MenuItem
            className={assignClassName('/realtime')}
            button
            onClick={() => history.push('/realtime')}
            selected={pathname === '/realtime'}
          >
            <FontAwesomeIcon icon={faMapMarkedAlt} fixedWidth />
            &ensp; Interactive map
          </MenuItem> */}
          {checkFleetManagementPermissions() && (
            <MenuItem
              className={assignClassName('/fleets')}
              button
              onClick={() => history.push('/fleets')}
              selected={pathname === '/fleets'}
            >
              <FontAwesomeIcon icon={faBus} fixedWidth />
              &ensp; {props.t('fleet_management_title')}
            </MenuItem>
          )}
          {checkBusManagementPermissions() && (
            <MenuItem
              className={assignClassName('/vehicles')}
              button
              onClick={() => history.push('/vehicles')}
              selected={pathname === '/vehicles'}
            >
              <FontAwesomeIcon icon={faBusAlt} fixedWidth />
              &ensp; {props.t('vehicle_management_title')}
            </MenuItem>
          )}
          {checkBusStopManagementPermissions() && (
            <MenuItem
              className={assignClassName('/network')}
              button
              onClick={() => history.push('/network')}
              selected={pathname === '/network'}
            >
              <FontAwesomeIcon icon={faMapSigns} fixedWidth />
              &ensp; {props.t('network_title')}
            </MenuItem>
          )}
          {checkDriverManagementPermissions() && (
            <MenuItem
              className={assignClassName('/drivers')}
              button
              onClick={() => history.push('/drivers')}
              selected={pathname === '/drivers'}
            >
              <FontAwesomeIcon icon={faUserTie} fixedWidth />
              &ensp; {props.t('driver_management_title')}
            </MenuItem>
          )}
          {checkDriverShiftManagementPermissions() && (
            <MenuItem
              className={assignClassName('/driverShifts')}
              button
              onClick={() => history.push('/driverShifts')}
              selected={pathname === '/driverShifts'}
            >
              <span style={{ paddingRight: '10px' }}>
                <FontAwesomeIcon icon={faClock} fixedWidth />
                &ensp; {props.t('driver_shifts_title')}
                {driverShiftsAreEndingSoon ? (
                  <FontAwesomeIcon
                    style={{ paddingLeft: '8px', height: '8px' }}
                    icon={faCircle}
                    color={colors.red}
                    size="xs"
                  />
                ) : null}
              </span>
            </MenuItem>
          )}
          {checkHistoryManagementPermissions() && (
            <MenuItem
              className={assignClassName('/rides')}
              button
              onClick={() => history.push('/rides')}
              selected={pathname === '/rides'}
              data-testid="rideManagmentButton"
            >
              <FontAwesomeIcon icon={faHistory} fixedWidth />
              &ensp; {props.t('ride_management_title')}
            </MenuItem>
          )}
          {checkHistoryManagementPermissions() && hasChartersFeature && (
            <MenuItem
              className={assignClassName('/charters')}
              button
              onClick={() => history.push('/charters')}
              selected={pathname === '/charters'}
            >
              <FontAwesomeIcon icon={faHistory} fixedWidth />
              &ensp; {props.t('charters')}
            </MenuItem>
          )}
          {checkRouteManagmentPermissions() && (
            <MenuItem
              className={assignClassName('/routes')}
              button
              onClick={() => history.push('/routes')}
              selected={pathname === '/routes'}
            >
              <FontAwesomeIcon icon={faRoute} fixedWidth />
              &ensp; {props.t('route_management_title')}
            </MenuItem>
          )}
          {checkOrganizationManagementPermissions() && (
            <MenuItem
              className={assignClassName('/organizations')}
              button
              onClick={() => history.push('/organizations')}
              selected={pathname === '/organizations'}
            >
              <FontAwesomeIcon icon={faCity} fixedWidth />
              &ensp; {props.t('organizations')}
            </MenuItem>
          )}
          {checkCalledInManagementPermissions() && (
            <MenuItem
              className={assignClassName('/calledInRequest')}
              button
              onClick={() => history.push('/calledInRequest')}
              selected={pathname === '/calledInRequest'}
            >
              <FontAwesomeIcon icon={faPhone} fixedWidth />
              &ensp; {props.t('called_in_requests')}
              {calledInRequestRequiresAction || displayUpcomingWarning ? (
                <FontAwesomeIcon
                  style={{ paddingLeft: '8px', height: '8px' }}
                  icon={faCircle}
                  color={colors.red}
                  size="xs"
                />
              ) : null}
            </MenuItem>
          )}
          {checkSystemParametersManagementPermissions() && (
            <MenuItem
              className={assignClassName('/settings')}
              button
              onClick={() => history.push('/settings')}
              selected={pathname === '/settings'}
            >
              <FontAwesomeIcon icon={faCog} fixedWidth />
              &ensp; {props.t('system_parameters_title')}
            </MenuItem>
          )}
          {checkPassengerManagementPermissions() && (
            <MenuItem
              className={assignClassName('/passengers')}
              button
              onClick={() => history.push('/passengers')}
              selected={pathname === '/passengers'}
              data-testid="passengersManagment"
            >
              <FontAwesomeIcon icon={faUsers} fixedWidth />
              &ensp; {props.t('passenger_management')}
            </MenuItem>
          )}
          {checkEmployeeManagementPermissions() && (
            <MenuItem
              className={assignClassName('/employees')}
              button
              onClick={() => history.push('/employees')}
              selected={pathname === '/employees'}
              data-testid="employeeManagment"
            >
              <FontAwesomeIcon icon={faUserTie} fixedWidth />
              &ensp; {props.t('employee_management_title')}
            </MenuItem>
          )}

          {hasFundsFeature && checkFundsManagmentPermissions() && (
            <MenuItem
              className={pathname === '/funds' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/funds')}
              selected={pathname === '/funds'}
            >
              <FontAwesomeIcon icon={faFunnelDollar} fixedWidth />
              &ensp; {props.t('funding_programs')}
              {showNotificationForFunds ? (
                <FontAwesomeIcon
                  style={{ paddingLeft: '8px' }}
                  icon={faExclamationTriangle}
                  color={colors.red}
                  size="lg"
                />
              ) : null}
            </MenuItem>
          )}

          {hasInvoiceFeature && checkInvoicesManagmentPermissions() && (
            <MenuItem
              className={pathname === '/invoices' ? 'selectedNavbarItem' : ''}
              button
              onClick={() => history.push('/invoices')}
              selected={pathname === '/invoices'}
            >
              <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth />
              &ensp; {props.t('invoices')}
            </MenuItem>
          )}

          {checkMyProfilePermissions() && (
            <MenuItem
              className={assignClassName('/profile')}
              button
              id="profileMenu"
              onClick={() => history.push('/profile')}
              selected={pathname === '/profile'}
            >
              <FontAwesomeIcon icon={faUser} fixedWidth />
              &ensp; {props.t('profile_title')}
            </MenuItem>
          )}
          {currentUser !== null && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://tally.so/r/mBxggA?app=engine&email=${currentUser.email}&agency=${currentUser.transit_agency_id}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <MenuItem id="feedback" button>
                <FontAwesomeIcon icon={faCommentDots} fixedWidth />
                &ensp; {props.t('give_feedback')}
              </MenuItem>
            </a>
          )}
          {checkBlaiseAdminDashboardPermissions() && (
            <MenuItem
              className={path === '/blaise-admin-dashboard' ? 'selectedNavbarItem' : ''}
              button
              id="profileMenu"
              onClick={() => history.push('/blaise-admin-dashboard')}
              selected={path === '/blaise-admin-dashboard'}
            >
              <FontAwesomeIcon icon={faLock} fixedWidth />
              &ensp; Blaise admin dashboard
            </MenuItem>
          )}
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography variant="caption" style={{ color: colors.blaiseGray }}>
            {config.version || ''}
          </Typography>
          {!isAuthenticated && (
            <Button id="loginButton" variant="outlined" onClick={() => loginWithRedirect()}>
              {props.t('login')}&ensp;
              <FontAwesomeIcon icon={faSignOutAlt} color={colors.red} fixedWidth />
            </Button>
          )}

          {isAuthenticated && (
            <Button
              id="logoutButton"
              variant="outlined"
              onClick={() => {
                setCurrentUser(null);
                logout({ returnTo: window.location.origin });
              }}
            >
              {props.t('logout')}&ensp;
              <FontAwesomeIcon icon={faSignOutAlt} color={colors.red} fixedWidth />
            </Button>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default withTranslation('common')(withRouter(NavBar));
