import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import config from './config';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import errors_fr from './translations/fr/errors.json';
import common_fr from './translations/fr/common.json';
import errors_en from './translations/en/errors.json';
import common_en from './translations/en/common.json';

const history = createBrowserHistory();

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false }, // React already does escaping
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
      errors: errors_en
    },
    fr: {
      common: common_fr,
      errors: errors_fr
    }
  },
  lng: navigator.languages,
  fallbackLng: 'en' // In case language detection fails, initialize to eng
});

// A function that routes the user to the right place
// after login
// eslint-disable-next-line
const onRedirectCallback = (appState) => {
  history.push(appState?.targetUrl || window.location.pathname);
};

// Set up Sentry error monitoring

// When debugging locally, set the tracesSampleRate to 1.0, and process.env.NODE_ENV === 'development'. On Production, we currently have the tracesSampleRate set to 0.2.
/* eslint-disable-next-line */
process.env.NODE_ENV === 'production' &&
  Sentry.init({
    dsn: 'https://6968d8e2baf94458ae85a2bdcf6fc6b3@o495723.ingest.sentry.io/5568966',
    integrations: [new BrowserTracing()],
    release: config.version,
    environment: config.environment,
    tracesSampleRate: 0.2
  });
ReactDOM.render(
  <Auth0Provider
    domain={config.auth0Domain}
    clientId={config.auth0ClientId}
    redirectUri={window.location.origin}
    audience={config.apiAudience}
    scope="read:agents"
  >
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
