import { AxiosResponse } from 'axios';
import authenticatedAxiosInstance from 'src/axios/axios-authorized';
import { IGoogleMapsAPILocation } from 'src/models/Location';

// See https://developers.google.com/maps/documentation/places/web-service/details for more details
interface IGetLocationOfPhoneRequestResponse {
  location: {
    html_attributions: string[];
    result: {
      formatted_address?: string;
      geometry?: {
        location: IGoogleMapsAPILocation;
        viewport: {
          northeast: IGoogleMapsAPILocation;
          southwest: IGoogleMapsAPILocation;
        };
      };
    };
    status: string;
    info_messages?: string[];
  };
}

export const getAllPhoneRequests = async (): Promise<AxiosResponse> => {
  const response = await authenticatedAxiosInstance.axios.get(`/allPhoneRequests`);

  return response.data;
};

export const cancelCalledInRequest = async (
  tripRequestId: number,
  passengerId: string,
  transitAgencyId: number,
  transitAgencyUserId: string
) => {
  const source = 'ENGINE';

  return await authenticatedAxiosInstance.axios.delete(
    `/trip-request?tripRequestId=${tripRequestId}&passengerId=${passengerId}&transitAgencyId=${transitAgencyId}&requestSource=${source}&requestTransitAgencyUserId=${transitAgencyUserId}`
  );
};

export const getLocationOfPhoneRequest = async (
  locationId: string | undefined,
  sessionToken: string
): Promise<IGetLocationOfPhoneRequestResponse> => {
  const response: AxiosResponse = await authenticatedAxiosInstance.axios.get(
    `/phoneRequests/selectLocation?placeId=${String(locationId)}&sessionToken=${sessionToken}`
  );

  return response.data;
};
