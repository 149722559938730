const blaiseBlue = '#07a7cb';
const blaiseGray = '#7A7A7A';
const blaiseLightGray = '#E6E6E6';
const blaiseGreen = '#00CDAD';
const blaiseLightGreen = '#f2faf8';
const blaiseDarkGreen = '#42980E';
const blaiseRed = '#F94346';
const blaisePurple = '#52479C';
const blaiseDarkPurple = '#3d3575';
const red = '#A23939';
const black = '#000000';
const white = '#ffffff';
const buttonGreyText = '#2b2a2a';
const yellow = '#FFA016';

const gray100 = '#F3F4F6';
const gray200 = '#E5E7EB';
const gray300 = '#D1D5DB';
const gray400 = '#9CA3AF';
const gray500 = '#6B7280';
const gray600 = '#4B5563';
const gray700 = '#374151';
const gray800 = '#1F2937';

const colors = {
  blaiseBlue,
  blaiseGreen,
  blaiseLightGreen,
  blaiseDarkGreen,
  blaiseGray,
  blaiseLightGray,
  blaiseRed,
  blaisePurple,
  blaiseDarkPurple,
  red,
  black,
  white,
  buttonGreyText,
  yellow,
  gray100,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
  gray700,
  gray800
};

export default colors;
