import { ILocationWithGeoJSON } from 'src/models/Location';
import authenticatedAxiosInstance from '../axios/axios-authorized';
import { IBusStop } from 'src/models/BusStop';

export const getTransitAgencyBusStops = async (
  taId: number
): Promise<{ busStops?: IBusStop[]; geojson?: ILocationWithGeoJSON }> => {
  const response = await authenticatedAxiosInstance.axios.get(`/busstops/${taId}`);

  return response.data;
};

export const getDepots = async (taId: number): Promise<IBusStop[]> => {
  const response = await authenticatedAxiosInstance.axios.get(`/busstops/${taId}/depots`);

  return response.data;
};

export const editStopName = async (stopId: number, name: string): Promise<string | null> => {
  return (await authenticatedAxiosInstance.axios.put(`/busstops/${stopId}`, { name })).data
    ?.message;
};
