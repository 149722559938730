import * as React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';
import produce from 'immer';
import { IPassenger } from 'src/models/Passenger';
import * as moment from 'moment';
import { Demand } from 'src/models/Trip';
import { FormattedFundForPayment } from 'src/models/Funds';
import { ITripRequestOrganizationBody } from 'src/models/Organization';
import { IAvailableShift } from 'src/api/driverShifts';

export type Coords = null | number;

export type Stop = {
  latitude: Coords;
  longitude: Coords;
  arrivalTime: null | moment.Moment;
  stopDurationSeconds: number;
  name: string;
  // the following are not needed when sending a charter request and will be omitted
  departureTime?: null | moment.Moment;
  type?: string;
  durationInSeconds?: number;
};

export type CharterRequestDetails = {
  passenger: IPassenger | null;
  tripDate: number | moment.Moment;
  origin: Stop;
  destination: Stop;
  stops: Stop[];
  passengersDemand: Demand;
  shift: IAvailableShift;
  grossCost: number;
  depositPaid: number;
  paymentType: string;
  payLaterType?: {
    paymentType: string;
    organizations: ITripRequestOrganizationBody[];
  };
  subsidyReduction: number;
  subsidyFunds: FormattedFundForPayment[];
  notes: string;
  totalDistanceMeters: number;
  totalTimeDurationSeconds: number;
};

const today = new Date();
const charterRequestWindowMin = today.setDate(today.getDate() + 1);

const initialRequestData: CharterRequestDetails = {
  passenger: null,
  tripDate: charterRequestWindowMin,
  origin: {
    latitude: null,
    longitude: null,
    arrivalTime: null,
    departureTime: null,
    type: 'origin',
    name: '',
    stopDurationSeconds: 0
  },
  destination: {
    latitude: null,
    longitude: null,
    arrivalTime: null,
    departureTime: null,
    type: 'destination',
    name: '',
    stopDurationSeconds: 0
  },
  stops: [],
  passengersDemand: {},
  shift: {} as IAvailableShift,
  paymentType: '',
  grossCost: 0,
  depositPaid: 0,
  subsidyReduction: 0,
  subsidyFunds: [],
  notes: '',
  totalDistanceMeters: 0,
  totalTimeDurationSeconds: 0
};

type PartialCharterRequestDetails = Partial<CharterRequestDetails>;

type UpcomingCharter = { charterId: number; displayNotice: boolean };

type CharterRequestStore = {
  charterRequestDetails: CharterRequestDetails;
  setCharterRequestDetails: (updatedDetails: PartialCharterRequestDetails) => void;
  resetCharterRequestDetails: () => void;
  upcomingChartersWithinTwoWeeks: UpcomingCharter[];
  setUpcomingChartersWithinTwoWeeks: (upcomingChartersWithinTwoWeeks: UpcomingCharter[]) => void;
};

export const charterRequestStore = create<CharterRequestStore>((set) => ({
  charterRequestDetails: initialRequestData,
  setCharterRequestDetails: (updatedDetails) =>
    set(
      produce((state) => {
        state.charterRequestDetails = { ...state.charterRequestDetails, ...updatedDetails };
      })
    ),
  resetCharterRequestDetails: () => set({ charterRequestDetails: initialRequestData }),
  upcomingChartersWithinTwoWeeks: [],
  setUpcomingChartersWithinTwoWeeks: (value) => set({ upcomingChartersWithinTwoWeeks: value })
}));

export const charterRequestHOC = <T extends CharterRequestStore = CharterRequestStore>(
  BaseComponent: React.ComponentType<T>
) => {
  return (props: Omit<T, keyof CharterRequestStore>) => {
    const store = charterRequestStore(
      (state) => ({
        charterRequestDetails: state.charterRequestDetails,
        setCharterRequestDetails: state.setCharterRequestDetails,
        resetCharterRequestDetails: state.resetCharterRequestDetails,
        upcomingChartersWithinTwoWeeks: state.upcomingChartersWithinTwoWeeks,
        setUpcomingChartersWithinTwoWeeks: state.setUpcomingChartersWithinTwoWeeks
      }),
      shallow
    );

    return <BaseComponent {...(props as T)} charterRequestStore={store} />;
  };
};
