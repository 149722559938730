import * as React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';

export interface DriverShiftStore {
  driverShiftsAreEndingSoon: boolean;
  noShiftsAfterDate: Date | null;
  setDriverShiftsAreEndingSoon: (value: boolean) => void;
  setNoShiftsAfterDate: (date: Date) => void;
}

export const driverShiftStore = create<DriverShiftStore>((set) => ({
  driverShiftsAreEndingSoon: false,
  noShiftsAfterDate: null,
  setDriverShiftsAreEndingSoon: (value) => set({ driverShiftsAreEndingSoon: value }),
  setNoShiftsAfterDate: (date) => set({ noShiftsAfterDate: date })
}));

export const driverShiftHOC = <T extends DriverShiftStore = DriverShiftStore>(
  BaseComponent: React.ComponentType<T>
) => {
  return (props: Omit<T, keyof DriverShiftStore>) => {
    const store = driverShiftStore(
      (state) => ({
        driverShiftsAreEndingSoon: state.driverShiftsAreEndingSoon,
        noShiftsAfterDate: state.noShiftsAfterDate,
        setDriverShiftsAreEndingSoon: state.setDriverShiftsAreEndingSoon,
        setNoShiftsAfterDate: state.setNoShiftsAfterDate
      }),
      shallow
    );

    return <BaseComponent {...(props as T)} driverShiftStore={store} />;
  };
};
