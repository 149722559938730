import { zonedTimeToUtc } from 'date-fns-tz';

type DateTime = string | number | Date;

// get unix timestamp in ms
export const getUnixTimeInMS = (dateTime: DateTime) => {
  return new Date(dateTime).getTime();
};

export const isSameDateTime = (dateTimePrimary: DateTime, dateTimeSecondary: DateTime) => {
  return getUnixTimeInMS(dateTimePrimary) === getUnixTimeInMS(dateTimeSecondary);
};

export const getISOStringInUTC = (dateTime: DateTime, transitAgencyTimeZone: string) => {
  return zonedTimeToUtc(dateTime, transitAgencyTimeZone).toISOString();
};
