export enum TransitAgencyFeatures {
  REAL_TIME_ROUTES = 'real_time_routes',
  ENABLED_3DS = 'enabled_3ds',
  PAPER_MANIFEST = 'paper_manifest',
  FARE_ASSISTANCE_FUNDS = 'fare_assistance_funds',
  TRIP_PURPOSE = 'trip_purpose',
  ORGANIZATIONS = 'organizations',
  CHARTERS = 'charters',
  INVOICES = 'invoices',
  FEES = 'fees',
  PASSENGER_LINKING = 'passenger_linking',
  CUSTOM_COLORS = 'custom_colors',
  NOTES_FOR_ADMIN = 'notes_for_admin',
  NOTES_FOR_DRIVER = 'passenger_profile_notes', // notes in passenger profile that are displayed to drivers
  DRIVER_NOTES_FOR_FEEDBACK = 'driver_notes',
  TRIP_REQUEST_VEHICLE_SELECTION = 'trip_request_vehicle_selection'
}

export enum TransitAgencyExtraDetails {
  FARE_SUBSIDY_ELIGIBILITY = 'fare_subsidy_eligibility'
}
