import authenticatedAxiosInstance from '../axios/axios-authorized';

export const getRideProposalsForRoute = async (routeId) => {
  try {
    const response = (
      await authenticatedAxiosInstance.axios.get(
        `/route/getPassengersForRouteForTransit/${routeId}`
      )
    ).data;

    return response?.data?.ride_proposals;
  } catch (error) {
    console.log(error);
  }
};

export const getRideProposalsAtRouteStop = async (routeId, stopSequence) => {
  return (
    await authenticatedAxiosInstance.axios.get(
      `/rides/${routeId}/rideProposalByBusStopId/${stopSequence}`
    )
  ).data;
};

export const manuallyArriveAtRouteStop = async (routeId, arrivalTime) => {
  return await authenticatedAxiosInstance.axios.put(`/route/${routeId}/stoparrival`, {
    arrivalTime
  });
};

export const manuallyDepartFromRouteStop = async (routeId, departureTime) => {
  return await authenticatedAxiosInstance.axios.post(`/route/${routeId}/stopdeparture`, {
    departureTime
  });
};

export const setActualTimeAtRouteStop = async (routeId, reqBody) => {
  return await authenticatedAxiosInstance.axios.put(
    `/routestops/times?routeId=${routeId}`,
    reqBody
  );
};

export const getStopsForRoute = async (routeId) => {
  const response = await authenticatedAxiosInstance.axios.get(`/routestops`, {
    params: {
      routeId: routeId
    }
  });

  return response.data;
};

export const getRoutePolyline = async (coords) => {
  const response = await authenticatedAxiosInstance.axios.post(`/route`, coords);

  return response.data;
};

export const getAllRouteStops = async (reqBody) => {
  const response = await authenticatedAxiosInstance.axios.get(`/allRoutestops`, reqBody);

  return response.data;
};

export const getShiftsForRoutes = async (reqBody) => {
  const response = await authenticatedAxiosInstance.axios.get(
    '/route/getShiftsWithRoutes',
    reqBody
  );

  return response.data;
};

export const getCalculatedWaypoints = async (reqBody) => {
  return (await authenticatedAxiosInstance.axios.post('/route/calculate-waypoints', reqBody)).data
    .waypoints;
};
