import authenticatedAxiosInstance from 'src/axios/axios-authorized';
import { ICharter, ICharterPaymentBody, ICharterRequestPayment } from 'src/models/Charter';
import { FormattedFundForPayment } from 'src/models/Funds';
import { ITripRequestOrganizationBody } from 'src/models/Organization';
import { Demand } from 'src/models/Trip';
import { Stop } from 'src/store/charterRequest';

export const getCharterTrips = async (
  startDate: string,
  endDate: string,
  getSimplified: boolean
) => {
  const response = await authenticatedAxiosInstance.axios.get<{ charterRequests: ICharter[] }>(
    `/charter?startDate=${startDate}&endDate=${endDate}&noIncludes=${getSimplified}`
  );

  return response.data;
};

type StopForRequest = Omit<Stop, 'departureTime' | 'type'>;

export type CharterRequestBody = {
  passengerId: string;
  transitAgencyId: number;
  shiftId: number;
  demandObj: Demand;
  grossTripPrice: number;
  depositAmount: number;
  paymentType: string;
  totalDistanceKm: number;
  startTime: string;
  estimatedEndTime: string;
  stops: StopForRequest[];
  notes: string;
  fundsUsed: FormattedFundForPayment[];
  organizations?: Omit<ITripRequestOrganizationBody, 'coverage'>[];
};

export const createCharterRequest = async (reqBody: CharterRequestBody) => {
  return await authenticatedAxiosInstance.axios.post('/charter/request', reqBody);
};

export const cancelCharterRequest = async (charterId: string, refund: boolean) => {
  const response = await authenticatedAxiosInstance.axios.delete<{ charterId: number }>(
    `/charter/request?charterId=${charterId}&refund=${refund}`
  );

  return response.data;
};

export const getCharterDetails = async (charterId: number) => {
  const response = await authenticatedAxiosInstance.axios.get<ICharter>(`/charter/${charterId}`);
  return response.data;
};

export const makeCharterPayment = async (charterId: string, reqBody: ICharterPaymentBody) => {
  const response = await authenticatedAxiosInstance.axios.post<ICharterRequestPayment>(
    `/charter/${charterId}/payment`,
    reqBody
  );

  return response.data;
};

export const updateCharterPaymentStatus = async (
  charterId: number,
  reqBody: { paymentStatus: string }
) => {
  await authenticatedAxiosInstance.axios.patch(`/charter/${charterId}/payment-status`, reqBody);
};
