// Enum for trip request payment methods
export enum PaymentType {
  CARD = 'card',
  TICKETS = 'tickets',
  PAY_ON_BOARD = 'pay_on_board',
  PAY_ON_BOARD_PASS = 'pay_on_board_pass',
  PAY_ON_BOARD_TICKET = 'pay_on_board_ticket',
  SMART_CARD = 'smart_card',
  PASS = 'pass',
  PAY_WITH_NEW_CARD = 'payWithNewCard',
  PAY_LATER = 'pay_later',
  ORGANIZATION = 'pay_later_organization'
}

// Enum for payment methods a passenger can have in their wallet
export enum PaymentMethod {
  BLAISE_BALANCE = 'blaise_balance'
}

export enum PassPaymentType {
  CASH = 'cash',
  ONE_TIME_PAYMENT = 'one_time_payment'
}

export enum PaymentStatus {
  PAID = 'paid',
  UNPAID = 'unpaid',
  REFUNDED = 'refunded',
  REFUNDED_TICKET_BALANCE = 'refunded_ticket_balance',
  // From stripe
  SUCCEEDED = 'succeeded',
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  REQUIRES_ACTION = 'requires_action',
  FAILED_3DS = 'failed_3ds'
}
