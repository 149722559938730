import { calledInRequestStore } from '../store/calledInRequest';
import { TripStatus } from '../enums/Trips';
import { RejectionReasons } from '../enums/RejectionReasonType';
import { getAllPhoneRequests } from '../api/phoneRequests';

export const checkCalledInRequestNeedsAction = async () => {
  try {
    const response = await getAllPhoneRequests();

    const filteredResponse = response.filter(
      (entry) =>
        entry.status !== TripStatus.UNKNOWN &&
        entry.status !== TripStatus.COMPLETED &&
        entry.status !== TripStatus.NO_SHOW &&
        entry.status !== TripStatus.CANCELLED &&
        entry.status !== TripStatus.PROCESSING
    );

    const numberOfTripsThatRequireAction = filteredResponse.reduce((acc, curr) => {
      if (
        curr.ride_proposals[0]?.need_details_call ||
        curr.ride_proposals[0]?.must_be_notified_for_reboot
      ) {
        acc++;
      }

      return acc;
    }, 0);

    // We only care about requests that need a call or are rejected (not generic rejection)
    const actionRequired = filteredResponse.some((request) => {
      const needsCall = request.ride_proposals?.some(
        (proposal) => proposal.need_details_call || proposal.must_be_notified_for_reboot
      );
      return (
        needsCall ||
        (request.status === TripStatus.REJECTED &&
          request?.rejection_reason &&
          request?.rejection_reason !== RejectionReasons.ALGO_GENERIC_ERROR &&
          request?.rejection_reason !== RejectionReasons.BACKEND_GENERIC_ERROR)
      );
    });

    calledInRequestStore.setState({
      calledInRequestRequiresAction: actionRequired,
      numberOfTripsThatRequireAction
    });
  } catch (error) {
    console.log('Unable to retrieve called in requests', error);
    calledInRequestStore.setState({ calledInRequestRequiresAction: false });
  }
};
