import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Link, Paper, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import colors from '../../assets/sass/colors';
import './HomeCard.scss';

const HomeCard = ({ children, title, subtitle, icon, seeMore }) => {
  const [elevation, setElevation] = useState(3);
  const { t } = useTranslation('common');
  const history = useHistory();
  const { internalRoute, externalLink } = seeMore;

  const goToSeeMore = (event) => {
    event.preventDefault();

    if (internalRoute) {
      history.push({
        pathname: internalRoute
      });
    }

    if (externalLink) {
      window.open(externalLink, '_blank');
    }
  };

  return (
    <Grid item xs={4} onMouseEnter={() => setElevation(6)} onMouseLeave={() => setElevation(1)}>
      <Paper className="paper" elevation={elevation}>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="title-container"
          >
            <Typography variant="h5">{t(`${title}`)}</Typography>
            <FontAwesomeIcon
              icon={icon}
              color={colors.blaiseGreen}
              size="2x"
              className="editIcon"
            />
          </Box>
          {subtitle && <Typography className="subtitle">{subtitle}</Typography>}
        </Box>

        <Box>{children}</Box>
        <Box style={{ height: '20px', alignSelf: 'flex-end' }}>
          {seeMore && (
            <Link onClick={goToSeeMore} style={{ cursor: 'pointer' }}>
              {t('see_more')}
            </Link>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default HomeCard;
