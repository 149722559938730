import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';

class Callback extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await this.props.auth0.handleAuthentication();

    this.props.history.replace('/');
  }

  render() {
    return <p></p>;
  }
}

export default withRouter(withAuth0(Callback));
